@import '../../../shared/styles/variable';

.main-content-view {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 20px 0;
  min-height: auto;
  height: 100%;

  @media screen and (min-width: @screen-md) {
    padding: 30px 32px 0;
  }
}

@primary-color: #0A8FDC;